import * as React from "react";
import MainLayout from "../layouts/main";

// markup
const NotFoundPage = () => {
    return (
        <MainLayout>
            OOPS! Looks like the page you're looking for isn't here.
        </MainLayout>
    );
};

export default NotFoundPage;
